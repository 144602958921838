(function() {
    'use strict';

    angular
        .module('onefacture')
        .controller('ClientController', ClientController);

    ClientController.$inject = ['$scope', '$http', '$timeout', 'toastr', '$window'];

    function ClientController($scope, $http, $timeout, toastr, $window) {
        let currentUser;
        let computerRef;
        let clientRef;
        let $form;
        var formCard = document.getElementById('form-card');
        var formPaypal = document.getElementById('form-paypal');
        var formOxxo = document.getElementById('form-oxxo');
        var formSpei = document.getElementById('form-spei');
        Conekta.setPublicKey('key_bbDdznsQNGkFwsDM3xXH2DA');
        //pruebas: key_GS2VzxyCab2jhSWZzMDstgw produccion: key_bbDdznsQNGkFwsDM3xXH2DA 
        $scope.requireFacture = true;
        $scope.validateClient = false;
        $scope.errorValidateClient = false;
        $scope.validating = false;
        $scope.sale = {
            professionalPlan: {
                priceBase: 599,
                quantity: 0,
                getDiscount: function () {
                    return this.quantity > 1 ? this.quantity < 4 ? 0.1 : 0.2 : 0;
                },
                getPrice: function () {
                    return (this.priceBase - (this.priceBase * this.getDiscount())) * this.quantity;
                }
            },
            herramientaCancellation: {
                priceBase: 350,
                quantity:0,
                getDiscount: function () {
                    return 0;
                },
                getPrice: function () {
                    return this.priceBase * this.quantity;
                }
            },
            herramientaDiot: {
                priceBase: 350,
                quantity: 0,
                getDiscount: function () {
                    return 0;
                },
                getPrice: function () {
                    return this.priceBase * this.quantity;
                }
            },
            getTotal: function () {
                return this.professionalPlan.getPrice() +
                       this.herramientaCancellation.getPrice() +
                       this.herramientaDiot.getPrice();
            },
            getTotalQuantity: function () {
                return this.professionalPlan.quantity +
                       this.herramientaCancellation.quantity +
                       this.herramientaDiot.quantity;
            }
        };
        $scope.usos = [
            {key: 'G01', value: 'Adquisición de mercancias'},
            {key: 'G02', value: 'Devoluciones, descuentos o bonificaciones'},
            {key: 'G03', value: 'Gastos en general'},
            {key: 'I01', value: 'Construcciones'},
            {key: 'I02', value: 'Mobilario y equipo de oficina por inversiones'},
            {key: 'I03', value: 'Equipo de transporte'},
            {key: 'I04', value: 'Equipo de computo y accesorios'},
            {key: 'I05', value: 'Dados, troqueles, moldes, matrices y herramental'},
            {key: 'I06', value: 'Comunicaciones telefónicas'},
            {key: 'I07', value: 'Comunicaciones satelitales'},
            {key: 'I08', value: 'Otra maquinaria y equipo'},
            {key: 'D01', value: 'Honorarios médicos, dentales y gastos hospitalarios.'},
            {key: 'D02', value: 'Gastos médicos por incapacidad o discapacidad'},
            {key: 'D03', value: 'Gastos funerales.'},
            {key: 'D04', value: 'Donativos.'},
            {key: 'D05', value: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).'},
            {key: 'D06', value: 'Aportaciones voluntarias al SAR.'},
            {key: 'D07', value: 'Primas por seguros de gastos médicos.'},
            {key: 'D08', value: 'Gastos de transportación escolar obligatoria.'},
            {key: 'D09', value: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.'},
            {key: 'D10', value: 'Pagos por servicios educativos (colegiaturas)'},
            {key: 'P01', value: 'Por definir'}
        ];
        $scope.selectedUso     = $scope.usos[2];
        $scope.selectedRegimen = '601';

        firebase.auth().onAuthStateChanged(function(user) {
          if (!user) {
            $window.location.href = '/iniciar-sesion';
          }
        });

        $scope.loadComputers = () => {
            firebase.auth().onAuthStateChanged(function(user) {
              if (user) {
                $timeout(() => {
                    clientRef       = firebase.database().ref('clients');
                    computerRef     = firebase.database().ref('computers');
                    currentUser     = user;
                    $scope.sale.uid = currentUser.uid;
                    clientRef.child(currentUser.uid).once('value', (clientSnapshot) => {
                        $scope.currentClient = clientSnapshot.val();
                        // initComputers();
                    });
                }, 0);
              } else {
                // No user is signed in.
              }
            //   console.log('onAuthStateChanged', user);
            });
        }

        function initComputers() {
            // TODO: prepare this when there are more than one record
            let i, found = 0, computer;
            computerRef = computerRef || firebase.database().ref('computers');
            $scope.loadingComputers = true;
            $scope.computers = [];
            for(i in $scope.currentClient.accessComputer) {
                if($scope.currentClient.accessComputer[i].type == 'Administrador') {
                    found ++;
                    computerRef.child(i).once('value', (snapshot) => {
                        $timeout(() => {
                            computer = snapshot.val();
                            computer.macAddress = snapshot.key;
                            $scope.computers.push(computer);
                            // It works but could be better
                            $scope.loadingComputers = false;
                        }, 0);
                    });
                }
            }

            if(found === 0) {
                $timeout(() => {
                    $scope.loadingComputers = false;
                }, 0);
            }
        };

        $scope.updateTableData = () => {
            $timeout(() => {
                $scope.sale.computers = $scope.computers.filter((computer) => {return computer.checked});
            }, 0);
        }

        $scope.initCard = () =>{
            card = new Card({
                form: 'form',
                container: '.card-wrapper',
                width: '100%',
                formSelectors: {
                    nameInput: 'input[name="nombre"]',
                    expiryInput: 'input[name="expiry-month"], input[name="expiry-year"]'
                },
                placeholders: {
                    number: '•••• •••• •••• ••••',
                    name: 'Nombre',
                    expiry: '••/••',
                    cvc: '•••'
                },
            });
        };

        $scope.initForm = () => {
            $form = $("#card-form");
        };

        $scope.scrollView = () => {
            $timeout(() => {
                switch ($scope.payOp) {
                    case 'tarjetaPago':
                        formCard.scrollIntoView({ behavior: 'smooth' });
                        break;
                    case 'paypalPago':
                        formPaypal.scrollIntoView({ behavior: 'smooth' });
                        break;
                    case 'oxxoPago':
                        formOxxo.scrollIntoView({ behavior: 'smooth' });
                        break;
                    case 'speiPago':
                        formSpei.scrollIntoView({ behavior: 'smooth' });
                        break;
                    default:
                        break;
                }
            }, 0);
        }

        $scope.validateData = function() {
            $scope.validateClient = false;
            $scope.errorValidateClient= false;
            if ($scope.requireFacture) {
                $scope.validating = true;
                var dataStampInvoice = {
                    rfc: formCard.querySelector('#rfc').value,
                    razon: formCard.querySelector('#razon').value,
                    domicilio: formCard.querySelector('#domicilio').value,
                    regimenFiscal: formCard.querySelector('#regimenFiscal').value
                };

                $http.post('/api/v1/payments/validatorClient', {
                    dataStampInvoice,
                })
                .then((response) => {
                    console.log(response,"response");
                    if(response.data.is_valid) {
                        $scope.validateClient = true;
                        $scope.validating = false;
                    } else {
                        let errorMessage = response.data.error.match(/"message":"(.*?)"/)[1];

                        $scope.errorValidateClient = errorMessage;
                        $scope.validating = false;
                    }
                });
            } else {
                console.log('No se requiere factura.');
            }
        };

       

        function conektaSuccessResponseHandler (token) {
          //Inserta el token_id en la forma para que se envíe al servidor
            $form.append($('<input type="hidden" name="conektaTokenId" id="conektaTokenId">').val(token.id));
        
            $form.get(0).submit(); //Hace submit
        };
        
        function conektaErrorResponseHandler (response) {
          $form.find(".card-errors").text(response.message_to_purchaser);
          $form.find("button").prop("disabled", false);
        };
        
        //jQuery para que genere el token después de dar click en submit
        $(function () {
          $("#card-form").submit(function(event) {
            // Previene hacer submit más de una vez
            $form.find("button").prop("disabled", true);
            Conekta.token.create($form, conektaSuccessResponseHandler, conektaErrorResponseHandler);
            return false;
          });
        });  
    }

})();
