(function() {
    'use strict';

    angular
        .module('onefacture')
        .controller('InvoiceToPdf', InvoiceToPdf);

    InvoiceToPdf.$inject = ['$scope', '$http', '$timeout', '$window'];

    function InvoiceToPdf($scope, $http, $timeout, $window) {
        $scope.invoice = false;

        $timeout(function() {
            const urlParams = new URLSearchParams($window.location.search);
            $scope.invoice_id = urlParams.get('invoiceId') || '';
            $scope.factura = $scope.invoice_id.trim() !== '';
            $scope.$apply();
        }, 100); 
        $scope.downloadInvoiceXml = () => {
            const $container = $('#invoices-container');
            $('#download-message-xml').remove();
            $container.append('<p id="download-message-xml">Descargando Factura XML...</p>');
            $http({
                method: 'GET',
                url: `/api/v1/payments/downloadXmlFromFacturapi/${$scope.invoice_id}`,
                responseType: 'blob'
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/xml' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Comprobante_${$scope.invoice_id}.xml`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                $('#download-message-xml').remove();
                $container.append('<p id="download-message-xml" style="color: green;">✅ Se descargó el XML correctamente.</p>');
            }).catch((error) => {
                $('#download-message-xml').remove();
                $container.append('<p id="download-message-xml" style="color: red;">❌ Error al descargar la factura. Inténtalo de nuevo más tarde.</p>');
            });
        };

        $scope.downloadInvoicePDF = () => {
            const $container = $('#invoices-container');
            $('#download-message').remove();
            $container.append('<p id="download-message">Descargando Factura PDF...</p>');
            $http({
                method: 'GET',
                url: `/api/v1/payments/downloadInvoicePDF/${$scope.invoice_id}`,
                responseType: 'blob'
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                // Crear una URL temporal para el archivo
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `Comprobante_${$scope.invoice_id}.pdf`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
                $('#download-message').remove();
                $container.append('<p id="download-message" style="color: green;">✅ Se descargó la factura en PDF correctamente.</p>');
            }).catch((error) => {
                $('#download-message').remove();
                $container.append('<p id="download-message" style="color: red;">❌ Error al descargar la factura. Inténtalo de nuevo más tarde.</p>');
            });
        };
    }

})();